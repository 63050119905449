import React from 'react'
import { graphql } from 'gatsby'
import ComponentsManager from '../utils/components-manager'
import Layout from '../layout/layout'
import SEO from "./seo";


/**
 * Landing page content type template
 * @param props
 * @returns {*}
 * @constructor
 */
const TextPage = (props) => {
    let node = props.data.nodeTextPage;

    let title = node.title;

    return (
        <Layout invertHeader={node.field_invert_header_colors}>

            <SEO title= description={node.field_summary} />
            <div>
                <ComponentsManager node={node}
                                   breadcrumbCategory={props.pageContext.breadcrumbCategory}
                                   components={node.relationships.field_components} />
            </div>
        </Layout>
    )
};
export default TextPage

/**
 * Landing page query direct node
 *
 * @NOTE Don't forget to wire component fragments!
 */
export const TextPageQuery = graphql`
query TextPageQuery($drupalNodeId: Int!) {
  nodeTextPage(drupal_internal__nid: {eq: $drupalNodeId})
  {
    title
    field_invert_header_colors
    field_summary
    
    relationships {
      field_components {
        __typename
        
        ...ParagraphHero
        ...ParagraphHeroIllustrated
        ...ParagraphsFixedContent
        ...ParagraphsSingleCardGradientBorderB
        ...ParagraphsSidebarLayout
        ...ParagraphsContactForm
        ...ParagraphsBluePhotoBanner
        ...ParagraphNewsletterSignup
        ...ParagraphsApplicationSteps
        ...ParagraphsPurpleCards
        ...ParagraphTextCtaImage
        ...ParagraphsBluePhotoBanner
        ...ParagraphsTwoCardsGradientBorder
        ...ParagraphsQuoteStatement
        ...ParagraphHeroGradient
        ...ParagraphsExplore
        
        ...ParagraphsExploreColleges
        ...ParagraphsVideoAndText
        ...ParagraphsThreeCardsBlueGradient
        ...ParagraphsSingleCardGradientBorder
        ...ParagraphsBlogPostsCards
        ...ParagraphsEventListing
        ...ParagraphHowItWorks
        ...ParagraphComponentIntro
        ...ParagraphsApplicationSteps
        ...ParagraphsBlueBackgroundText
        
      }
    }
  }
}`;
